import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ThankYouPage.css';

const CheckOrder = () => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const { paymentIntentId } = useParams(); // Get paymentIntentId from URL

  useEffect(() => {
    console.log('Sending request for Payment Intent:', paymentIntentId); // Log the static ID

    const fetchOrder = async () => {
      try {
        const response = await axios.post(`${apiUrl}/orders/`, {
          payment_intent_id: paymentIntentId // Send the static ID in the request body
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        console.log('Order Response:', response.data); // Log the response data
        setOrder(response.data);
      } catch (err) {
        console.error('Failed to fetch order:', err); // Log the error
        setError('Failed to fetch order');
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [paymentIntentId, apiUrl]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  if (!order) return <p>No order found</p>;

  const {
    payment_intent, amount, currency, email,
    shipping_first_name, shipping_last_name,
    shipping_address1, shipping_address2, shipping_city,
    shipping_state, shipping_country, store_name, cart_info
  } = order;

  const total = (amount / 100).toFixed(2);
  const customerName = `${shipping_first_name} ${shipping_last_name}`;

  return (
    <div className="thank-you-page">
      <div className="left-column">
        <h1>{store_name}</h1>
        <div className="order-confirmation">
          <span className="checkmark">✓</span>
          <div>
            <p>Order #{payment_intent}</p>
            <p className="thank-you">Thank you {shipping_first_name}!</p>
          </div>
        </div>

        <div className="info-box">
          <h2>Your order is confirmed</h2>
          <p>You'll receive a confirmation email with your order number shortly.</p>
        </div>

        <div className="info-box">
          <h2>Order Details</h2>
          <p>Order Number: {payment_intent}</p>
          <p>Customer Name: {customerName}</p>
          <p>Email: {email}</p>
          <p>Total: ${total}</p>
        </div>

        <div className="info-box">
          <h2>Shipping Address</h2>
          <p>{shipping_address1}</p>
          <p>{shipping_address2}</p>
          <p>{shipping_city}, {shipping_state}</p>
          <p>{shipping_country}</p>
        </div>

        <div className="info-box">
          <h2>Cart Items</h2>
          {cart_info && cart_info.map((item, index) => (
            <div key={index} className="cart-item">
              <p>{item.title}</p>
              <p>Quantity: {item.quantity}</p>
              <p>Price: ${(item.price / 100).toFixed(2)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CheckOrder;
