import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from "./views/CheckoutFormView";
import ThankYouPage from "./views/ThankYouPageView";
import CheckOrder from "./views/CheckOrderView";

import './App.css';

// Replace with your actual Stripe public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const App = () => {
  return (
    <Router>
      <div className="app">
        <div className="checkout-container">
          <Elements stripe={stripePromise}>
            <Routes>
              <Route path="/checkout" element={<CheckoutForm />} />
              <Route path="/thank-you" element={<ThankYouPage />} />
              <Route path="/order/:paymentIntentId" element={<CheckOrder />} /> {/* Updated here */}
            </Routes>
          </Elements>
        </div>
      </div>
    </Router>
  );
};

export default App;
