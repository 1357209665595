import React from 'react';
import { useLocation } from 'react-router-dom';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import './ThankYouPage.css';

// Define styles for PDF
const styles = StyleSheet.create({
  page: { padding: 30 },
  title: { fontSize: 24, marginBottom: 30 },
  section: { margin: 10, padding: 10 },
  row: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 },
  bold: { fontWeight: 'bold' },
});

// Invoice component
const Invoice = ({ orderNumber, customerName, shippingDetails, cartItems, total }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>Invoice</Text>

      <View style={styles.section}>
        <Text style={styles.bold}>Order Number: {orderNumber}</Text>
        <Text>Customer: {customerName}</Text>
        <Text>Date: {new Date().toLocaleDateString()}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.bold}>Shipping Address:</Text>
        <Text>{shippingDetails.address1}</Text>
        <Text>{shippingDetails.address2}</Text>
        <Text>{shippingDetails.city}, {shippingDetails.state}</Text>
        <Text>{shippingDetails.country}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.bold}>Items:</Text>
        {cartItems.map((item, index) => (
          <View key={index} style={styles.row}>
            <Text>{item.title} x {item.quantity}</Text>
            <Text>${(item.price * item.quantity / 100).toFixed(2)}</Text>
          </View>
        ))}
      </View>

      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.bold}>Total:</Text>
          <Text style={styles.bold}>${total}</Text>
        </View>
      </View>
    </Page>
  </Document>
);

const ThankYouPage = () => {
  const location = useLocation();
  const { paymentIntent, shippingDetails, cartItems, total, store } = location.state || {};

  const orderNumber = paymentIntent?.id || "N/A";
  const storeName = store || "N/A";
  const customerName = `${shippingDetails?.firstName} ${shippingDetails?.lastName}`;

  return (
    <div className="thank-you-page">
      <div className="left-column">
        <h1>{storeName}</h1>
        <div className="order-confirmation">
          <span className="checkmark">✓</span>
          <div>
            <p>Order #{orderNumber}</p>
            <p className="thank-you">Thank you {shippingDetails?.firstName}!</p>
          </div>
        </div>

        <div className="info-box">
          <h2>Your order is confirmed</h2>
          <p>You'll receive a confirmation email with your order number shortly.</p>
        </div>

        <div className="info-box">
          <h2>Download your tickets</h2>
          <p>Please download your tickets or transfer them to your guests.</p>
        </div>

        <div className="tickets">
          {cartItems && cartItems.map((item, index) => (
            <div key={index} className="ticket">
              <div>
                <p>{customerName}</p>
                <p>{item.title}</p>
                <p>#{Math.random().toString().slice(2, 11)}</p>
              </div>
              <div className="ticket-actions">
                <button>Transfer</button>
                <button>PDF</button>
                <button className="apple-wallet">Add to Apple Wallet</button>
              </div>
            </div>
          ))}
        </div>

        <div className="customer-info">
          <h2>Customer information</h2>
          <div className="info-columns">
            <div>
              <h3>Contact information</h3>
              <p>{paymentIntent?.billing_details?.email || "Email not provided"}</p>
            </div>
            <div>
              <h3>Payment method</h3>
              <p>Card ending with {paymentIntent?.payment_method_details?.card?.last4 || "****"} - ${(total / 100).toFixed(2)}</p>
              <h3>Billing address</h3>
              <p>{customerName}</p>
              <p>{shippingDetails?.address1}</p>
              <p>{shippingDetails?.address2}</p>
              <p>{shippingDetails?.city}, {shippingDetails?.state}</p>
              <p>{shippingDetails?.country}</p>
            </div>
          </div>
        </div>

        <div className="invoice-download">
          <PDFDownloadLink
            document={
              <Invoice
                orderNumber={orderNumber}
                customerName={customerName}
                shippingDetails={shippingDetails}
                cartItems={cartItems}
                total={(total / 100).toFixed(2)}
              />
            }
            fileName={`invoice_${orderNumber}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Generating invoice...' : 'Download Invoice'
            }
          </PDFDownloadLink>
        </div>
      </div>

      <div className="right-column">
        <div className="cart-info">
          {cartItems && cartItems.map((item, index) => (
            <div key={index} className="cart-item">
              <img src={item.image} alt={item.title} />
              <div>
                <h3>{item.title}</h3>
                <p>Quantity: {item.quantity}</p>
              </div>
              <p className="price">${(item.price / 100).toFixed(2)}</p>
            </div>
          ))}
          <div className="cart-summary">
            <div>
              <p>Subtotal</p>
              <p>${(total / 100).toFixed(2)}</p>
            </div>
            <div>
              <p>Taxes</p>
              <p>Included</p>
            </div>
            <div className="total">
              <p>Total</p>
              <p>USD ${(total / 100).toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;