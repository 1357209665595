import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getCode, getNames } from 'country-list';
import '../App.css';
import { useNavigate } from 'react-router-dom';


// Replace with your actual Stripe public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('US');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [fullName, setFullName] = useState('');
  const [postal, setPostal] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [coupon, setCoupon] = useState('');
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [store, setStore] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;


  useEffect(() => {
    // Get country names and reorder to place 'United States' first
    const countryNames = getNames();

    // Separate out 'United States' and 'United Kingdom', and sort the rest
    const orderedCountries = ['United States', 'United Kingdom', ...countryNames
      .filter(name => name !== 'United States' && name !== 'United Kingdom')
      .sort() // Sort remaining countries alphabetically
    ];

    setCountries(orderedCountries);

    // Fetch and decrypt cart data from URL
    const queryParams = new URLSearchParams(window.location.search);
    const cartData = queryParams.get('cart');
    if (cartData) {
      try {
        const decryptedCart = JSON.parse(decodeURIComponent(cartData));
        setCartItems(decryptedCart.items);
        setTotal(decryptedCart.total);
        const storeName = queryParams.get('store');
        setStore(storeName)

        // Clean the URL after fetching cart data
        window.history.replaceState(null, '', window.location.pathname);
      } catch (error) {
        console.error('Error parsing cart data:', error);
      }
    }
  }, []);

  const TrustBadges = () => {
    return (
      <div className="trust-badges-container">
        <div className="trust-badge">
          <img
            alt="Nortonn"
            title="f422ee40-6cf3-483c-8ec4-625bf06edf0a.Nortonn"
            className="trust-badge-image"
            src="https://assets.lightfunnels.com/account-34091/images_library/d777658a-7317-4b5f-ac97-e226147510bf.svg"
          />
          <div className="trust-badge-spacer"></div>
          <img
            alt="Ssll"
            title="58735118-56aa-47d9-a1d7-379671b905ed.Ssll"
            className="trust-badge-image"
            src="https://assets.lightfunnels.com/account-34091/images_library/935746b5-752e-4b4f-b2ab-dade72113bb6.svg"
          />
          <div className="trust-badge-spacer"></div>
          <img
            alt="Verisignn"
            title="4fcaaa9e-3cf0-4eb2-aa61-e141fc655a41.Verisignn"
            className="trust-badge-image"
            src="https://assets.lightfunnels.com/account-34091/images_library/33b0c5d4-b6f1-4d15-81a8-59ef94823e91.svg"
          />
        </div>
      </div>
    );
  };

// Inside the CheckoutForm component
const navigate = useNavigate();

const handleSubmit = async (event) => {
  event.preventDefault();

  if (!stripe || !elements) {
    return;
  }

  setLoading(true);

  // Step 1: Create PaymentIntent on the backend
  const { clientSecret, error: backendError } = await fetch(`${apiUrl}/create-payment-intent/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      amount: total,
      email: email,
      currency: 'usd',
    }),
  }).then(res => res.json());

  if (backendError) {
    console.error('Backend error:', backendError);
    setLoading(false);
    return;
  }

  // Step 2: Confirm the payment using Stripe
  const cardElement = elements.getElement(CardElement);

  const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card: cardElement,
      billing_details: {
        name: `${firstName} ${lastName}`,
        email,
        address: {
          line1: address1,
          line2: address2,
          city,
          state,
          country: getCode(country),
        },
      },
    },
  });

  if (stripeError) {
    console.error('Stripe error:', stripeError);
    setLoading(false);
    return;
  }

  // Step 3: Create an order in the backend
  const { orderId, error: orderError } = await fetch(`${apiUrl}/create-order/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      payment_intent_id: paymentIntent.id,
      email: email,
      amount: total,
      currency: 'usd',
      cart_info: cartItems,
      shipping_details: {
        first_name: firstName,
        last_name: lastName,
        address1,
        address2,
        city,
        state,
        phone,
        country: country,
      },
      store_name: store,
    }),
  }).then(res => res.json());

  if (orderError) {
    console.error('Order error:', orderError);
    setLoading(false);
    return;
  }

  // Step 4: Redirect to the thank you page with order details
  navigate('/thank-you', {
    state: {
      paymentIntent,
      shippingDetails: { firstName, lastName, address1, address2, city, state, country },
      cartItems,
      total,
      store,
      orderId,
    }
  });
};




  const handleApplyCoupon = () => {
    // Implement coupon logic here
    console.log('Applying coupon:', coupon);
    // Update total based on coupon
  };

  return (
    <div className="app">
      <header className="top-banner">
        FREE SHIPPING FOR ORDERS OVER $45.00 USD!
      </header>

      <nav className="main-nav">
        <div className="logo">{store}</div>
      </nav>

      <form onSubmit={handleSubmit} className="checkout-form">
        <div className="customer-info">
          <h2>Contact information</h2>
          <input type="email" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
          <label className="checkbox-label">
            <input type="checkbox" /> Send me news and exclusive offers
          </label>

          <h2>Shipping information</h2>
          <select value={country} onChange={(e) => setCountry(e.target.value)}>
            {countries.map((countryName, index) => (
              <option key={index} value={countryName}>{countryName}</option>
            ))}
          </select>
          <div className="name-fields">
            <input type="text" placeholder="First name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            <input type="text" placeholder="Last name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </div>
          <input type="text" placeholder="Street Adress" value={address1} onChange={(e) => setAddress1(e.target.value)} />
           <div className="address-line">
            <input type="text" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
            <input type="text" placeholder="Postal code" value={address2} onChange={(e) => setAddress2(e.target.value)} />
            <input type="text" placeholder="State/Province" value={state} onChange={(e) => setState(e.target.value)} />
          </div>
          <input type="text" placeholder="Phone number" value={phone} onChange={(e) => setPhone(e.target.value)} />
          <h2>Payment method:</h2>
          <CardElement options={{style: {base: {fontSize: '16px'}}}} />

          <button type="submit" className="checkout-button" disabled={!stripe || loading}>
            {loading ? 'Processing...' : 'Buy now'}
          </button>
        </div>

        <div className="order-summary">
          <h2>Order summary</h2>
          {cartItems.map((item, index) => (
            <div key={index} className="product-item">
              <img src={item.image} alt={item.title} />
              <div className="product-details">
                <h3>{item.title}</h3>
                <p>Quantity: {item.quantity}</p>
                <p>Price: ${(item.price / 100).toFixed(2)}</p>
              </div>
              <div className="product-price">${(item.price / 100).toFixed(2)}</div>
            </div>
          ))}

          <div className="coupon-section">
            <h3>Discount</h3>
            <div className="coupon-input">
              <input type="text" placeholder="Enter coupon code" value={coupon} onChange={(e) => setCoupon(e.target.value)} />
              <button type="button" onClick={handleApplyCoupon}>Apply</button>
            </div>
          </div>

          <div className="order-totals">
            <div className="subtotal">
              <span>Subtotal</span>
              <span>${(total / 100).toFixed(2)}</span>
            </div>
            <div className="shipping">
              <span>Shipping:</span>
              <span> Free</span>
            </div>
          </div>

          <div className="total">
            <span>Total</span>
            <span>${(total / 100).toFixed(2)}</span>
          </div>

          <TrustBadges />
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;
